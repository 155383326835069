<template>
    <!-- Make a div wrapped slider,set height and width -->
<div>
    <div class="box bottom common-width" v-if='news.length!=0'>

      <div style="margin-top: 30px">
        <p class="banner-title title-w" style="margin-bottom: 0;">
          通知、新闻
      
          <span style="font-size:12px;color:rgb(139 136 136);margin-left:20px;    cursor: pointer;" @click="go(`info?name=${title}`)">查看更多 》</span>
        </p>
      </div>
    </div>
    <div class='common-width'  v-if='news.length!=0'>
<!-- 
       <slider ref="slider" :options="options" @slide='slide' @tap='onTap' @init='onInit' >

     
           <slideritem v-for="(item,index) in news" :key="index" >
            <div class="card">
                <p class="title">{{item.title}}</p>
                <p class="right" >查看详情</p>
            </div>
      
    </slideritem>
     
       </slider> -->
       <el-carousel height="107px" :interval="7000">
      <el-carousel-item v-for="(item,index) in carL" :key="item">
      <div v-if="index==0">
        <div class="card" v-for="(yy,ii) in news" :key="ii" v-show="ii<=3" @click="detailFun(yy.content,yy.url)">
                <p class="title">{{ yy.title }}</p>
                <p class="right" >查看详情</p>
            </div>
      </div>
      <div v-if="index==1">
        <div class="card" v-for="(yy,ii) in news" :key="ii" v-show="ii>=4 && ii<8"  @click="detailFun(yy.content,yy.url)">
          <p class="title">{{ yy.title }}</p>
                <p class="right" >查看详情</p>
            </div>
      </div>
      <div v-if="index==2">
        <div class="card" v-for="(yy,ii) in news" :key="ii" v-show="ii>=8 && ii<12"  @click="detailFun(yy.content,yy.url)">
          <p class="title">{{ yy.title }}</p>
                <p class="right" >查看详情</p>
            </div>
      </div>
      <div v-if="index==3">
        <div class="card" v-for="(yy,ii) in news" :key="ii" v-show="ii>=12 && ii<16"  @click="detailFun(yy.content,yy.url)">
          <p class="title">{{ yy.title }}</p>
                <p class="right" >查看详情</p>
            </div>
      </div>
        
        
           
          
      </el-carousel-item>
    </el-carousel>
    </div>
</div>
    </template>
    <script>
    // import slider components
    // import { slider, slideritem } from 'vue-concise-slider'
    export default {
    // el: '#app',
    props:{
        news:Array,
        title:String
    },
    data () {
       return {
        carL:0,
         //data list [array]
        //  someList : [
        //    {
        //      html: 'slider1',
        //      style: {
        //        'background': '#4abf8a',
        //        'width': '23.5%',
        //        'margin-right': '2%'
        //      }
        //    },
        //    {
        //      html: 'slider2',
        //      style: {
        //        'background': '#4bbfc3',
        //        'width': '23.5%',
        //        'margin-right': '2%'
        //      }
        //    },
        //    {
        //      html: 'slider3',
        //      style: {
        //        'background': '#7baabe',
        //        'width': '23.5%',
        //        'margin-right': '2%'
        //      }
        //    },
        //    {
        //      html: 'slider4',
        //      style: {
        //        'background': '#7caabe',
        //        'width': '23.5%',
        //        'margin-right': '2%'
        //      }
        //    },
        //    {
        //      html: 'slider5',
        //      style: {
        //        'background': '#4abf8a',
        //        'width': '23.5%',
        //        'margin-right': '2%'
        //      }
        //    },
        //    {
        //      html: 'slider6',
        //      style: {
        //        'background': '#4bbfc3',
        //        'width': '23.5%',
        //        'margin-right': '2%'
        //      }
        //    },
        //    {
        //      html: 'slider7',
        //      style: {
        //        'background': '#7baabe',
        //        'width': '23.5%',
        //        'margin-right': '2%'
        //      }
        //    },
        //    {
        //      html: 'slider8',
        //      style: {
        //        'background': '#7caabe',
        //        'width': '23.5%',
        //        'margin-right': '2%'
        //      }
        //    }
        //  ],
         //Slider configuration [obj]
         options: {
           currentPage: 0,
           tracking: false,
           thresholdDistance: 100,
           thresholdTime: 300,
           infinite: 4,
           slidesToScroll: 4,
           itemAnimation: true,
        //    pagination:false,
           loop: true, // 无限循环
          autoplay: 1000000 // 自动播放:时间[ms]
         }
       }
     },
     watch:{
      news(o,n){
        this.news = o;
        console.log(n)
        console.log( this.news )
        if(this.news.length>4&&this.news.length<=8){
        this.carL =2
      }
      else if(this.news.length<=4){
        this.carL =1
      }
      else if(this.news.length>8 && this.news.length<=12){
        this.carL =3
      }
      else if(this.news.length>12 && this.news.length<=16){
        this.carL =4
      }
      else if(this.news.length ==0){
        this.carL =0
      }
      console.log(this.carL)
      }
     },
     mounted() {
      // if(this.news.length>4&&this.news.length<=8){
      //   this.carL =2
      // }
      // else if(this.news.length<=4){
      //   this.carL =1
      // }
      // else if(this.news.length>8){
      //   this.carL =3
      // }
      // else if(this.news.length ==0){
      //   this.carL =0
      // }
      console.log(this.news)
    console.log( this.carL)

     },
     methods:{
      detailFun(content,url){
        if(url){
          window.open(url)
        }else {
          this.$router.push({ path: 'infoDetail' });
        window.localStorage.setItem('anjing_ds_content',content)
        }
       

      },
        go(url){
            this.$router.push({ path: url });
        },
        onTap(e){
            console.log(e)
        },
        slide(){},
        onInit(e){
            console.log(e)},
            enterFun(){
                this.options.loop = false;
                this.options.autoplay = null;
                console.log(0)
            },    
            leaveFun(){
                this.options.loop = true;
                this.options.autoplay = 1000000;
                console.log(1)
            }
            
     },
     components: {
      //  slider,
      //  slideritem
     }
    }
    </script>
    <style>
.slider-item{
    width:25%;
    padding-left:1px;
}
/deep/ .ant-card-bordered{
    width:100%;
}
.box {
  /* max-width: 1240px;
  margin: 0 auto; */
  text-align: left;
}
.box-title {
  font-size: 30px;
  /* font-weight: 400; */
  color: #000;
}
.box-detail {
  white-space: normal;
  word-wrap: break-word;
  word-break: break-all;
  padding: 20px 0;
}
.card{
  cursor:pointer;
    text-align:left;
    color:#000;
    box-shadow: 1px 1px 2px 0px #ccc;
    width: 24%;
   float:left;
    border-top:3px solid #0098ff;
    padding:10px;
    background:#effaff;
    margin-right:1%

}
.card .title{
    font-size:16px;
    font-weight:bold;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    color: #4e4d4d;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
    min-height: 47px;

}
.card .right{
    font-size:14px;
    text-align:right;
    margin-bottom: 0;
    color: #0098ff;
}
.banner-title {
  font-size: 22px;
  font-weight: bold;
  color: #000;
  width: 400px;
}
.news .title-w {
  padding: 5px 0;
}
@media (max-width: 800px) {
.card{
  width:21%;
}
.card .title,.card .right{
  font-size:12px;
}
.banner-title{
  font-size:16px;
}
}
</style>